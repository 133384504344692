@import "variables";
// includes
@import "includes/functions";
@import "includes/normalize";
@import "includes/45p";
@import "includes/fonts";
@import "node_modules/owl.carousel/dist/assets/owl.carousel.min";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/magnific-popup/dist/magnific-popup";

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
}

body {
    background-color: $black;
    color: $primary-color;
    font-family: $primary-font;
    font-weight: normal;
    font-size: rem(16);
    line-height: 1.2;
    overflow: hidden;
}

a{
    color: inherit;
    @include hover{
        color: $primary-color-d;
    }
}

.container{
    max-width: rem(1280);
}

#header{
    .container{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        z-index: 100;
        padding-bottom: rem(20);
        h1{
            padding-top: rem(20);
            width: rem(170);
            margin-bottom: rem(-80);
            @media screen and (max-width: 699px){
                padding-top: rem(10);
                margin-bottom: rem(-65);
            }
        }
    }

    a{
        text-transform: uppercase;
        font-family: $primary-font-bold;
        text-decoration: underline;
        &+a{
            margin-left: rem(30);
        }
    }

    
    @media screen and (max-width: 699px){
        .menu{
            position: absolute;
            z-index: 300;
            top: rem(-600);
            left: 0;
            right: 0;
            text-align: center;
            padding: rem(60) rem(30) rem(30);
            background-color: $black;
            a{
                display: block;
            }
            a+a{
                margin: rem(20) 0 0;
            }
        }
    }
}

#videos{
    font-size: rem(16);
    text-transform: uppercase;
    .item{
        display: flex;
        align-items: center;
    }

    .title{
        flex-basis: rem(30);
        position: relative;
        text-transform: uppercase;
        font-family: $primary-font-bold;
        a{
            display: none;
            position: absolute;
            left: 50%;
            top: 50%;
            transform-origin: center;
            white-space: nowrap;
            &.prev{
                transform: translate(-50%,-50%) rotate(-90deg);
            }
            &.next{
                transform: translate(-50%,-50%) rotate(90deg);
            }
        }
    }
    .active .item .title a{
        display: block;
        margin: 0;
        padding-bottom: rem(30);
        z-index: 150;
    }

    .video-wrap{
        flex: 1;
        position: relative;
        a{
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-decoration: underline;
            font-family: $primary-font-bold;
            text-align: center;
            line-height: 1.4;
            span{
                display: block;
                font-size: .8em;
            }
        }
    }

    @media screen and (min-width: 800px){
        .mobile{
            display: none;
        }

        .swipe{
            display: none;
        }
    }

    @media screen and (max-width: 799px){
        .item .title{
            display: none;
        }

        .desktop{
            display: none;
        }

        .media-responsive{
            padding-bottom: 177%;
        }

        .swipe{
            display: block;
            text-align: center;
            font-size: rem(12);
            letter-spacing: .05em;
            font-family: $primary-font-bold;
        }
    }
}

#cta{
    text-transform: uppercase;
    text-align: center;
    margin: rem(20) 0;

    h2{
        font-family: $primary-font-bold;
        font-weight: normal;
        font-size: rem(21);
        line-height: 1;
        margin-bottom: rem(-2);
    }

    p{
        font-size: rem(14);
        letter-spacing: .05em;
    }

    a{
        display: inline-block;
        color: $black;
        background-color: $primary-color;
        font-family: $primary-font-bold;
        padding: rem(3) rem(20);
        border: 1px solid $primary-color;
        font-size: rem(13);
        @include hover{
            color: $primary-color;
            background-color: $black;
        }
        @media screen and (max-width: 400px){
            display: block; 
            margin: 0 auto rem(10);
        }
    }
}

#tour{
    h2{
        text-align: center;
        text-transform: uppercase;
        margin: 1em auto;
    }
    .bit-follow-section-wrapper{ 
        display: none; 
    }
    .bit-play-my-city-wrapper{
        display: none;
    }
    .bit-widget>div:last-child{
        display: none;
    }
}

#footer{
    text-align: center;
    .social{
        display: inline-block;
        vertical-align: middle;
        margin: rem(15) rem(10); 
        font-size: rem(20);
        li{
            display: inline-block;
            &+li{
                margin-left: .5em;
            }
        }
    }

    .newsletter{
        display: inline-block;
        vertical-align: middle;
        margin: rem(15) rem(10);

        input{
            font-size: rem(11);
            padding: rem(3) 0 rem(4);
            text-transform: uppercase;
            color: $primary-color;
            border-bottom: 1px solid $primary-color;
            &::placeholder{
                color:$primary-color-d;
                letter-spacing: .05em;
            }
        }

        button{
            display: inline-block;
            color: $black;
            background-color: $primary-color;
            font-family: $primary-font-bold;
            padding: rem(3) rem(8);
            border: 1px solid $primary-color;
            font-size: rem(12);
            @include hover{
                color: $primary-color;
                background-color: $black;
            }
        }
    }

    .copyright{
        margin-top: rem(35);
        font-size: rem(8);
        font-family: sans-serif; 
        letter-spacing: .08em;
        text-transform: uppercase;
    }
}

.popup{
    position: relative;
    padding: rem(100) rem(100) rem(20);
    background-color: $black;
    width: rem(900);
    max-width: 100%;
    margin: rem(30) auto;
    text-align: center;
    text-transform: uppercase;
    
    @media screen and (max-width: 799px){
        padding: rem(20);
    }

    picture{
        display: block;
        img{
            display: block;
        }
    }
    .logo{
        position: absolute;
        top: rem(20);
        left: rem(20);
        width: rem(120);
        height: auto;
        @media screen and (max-width: 799px){
            position: static;
            margin-bottom: rem(15);
        }
    }

    p{
        font-family: $primary-font-bold;
        font-weight: normal;
        font-size: rem(16);
        line-height: 1;
        margin: rem(20) 0 .5em;
    }

    a{
        display: inline-block;
        color: $black;
        background-color: $primary-color;
        font-family: $primary-font-bold;
        padding: rem(3) rem(20);
        border: 1px solid $primary-color;
        font-size: rem(16);
        @include hover{
            color: $primary-color;
            background-color: $black;
        }
    }

    .mfp-close{
        color: $primary-color;
        font-family: $primary-font-bold;
        font-weight: normal;
        text-decoration: underline;
        right: rem(20);
        top: 0;
        opacity: 1;
        width: auto;
        height: auto;
        font-size: rem(10);
        @include hover{
            color: $primary-color-d;
        }
    }
}